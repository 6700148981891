import zummo from '../img/logos/zummo.jpg';
// import jumpshot from '../img/logos/jumpshot.png';
import fivestar from '../img/logos/5star.png';
import giv from '../img/logos/giv.jpg';
import grimmfilms from '../img/logos/grimmfilms.png';
// import heard from '../img/logos/heard.jpg';
// import jimmy from '../img/logos/jimmy.jpg';
import oceanoutpost from '../img/logos/oceanoutpost.jpg';
import offthedock from '../img/logos/offthedock.jpg';
import rsk from '../img/logos/rsk.png';
import strikeforce from '../img/logos/strikeforce.jpg';
import tss from '../img/logos/tss.png';
import wander from '../img/logos/wander.png';
import watsonbeats from '../img/logos/watsonbeats.jpg';
import whitediamond from '../img/logos/whitediamond.jpg';

const Logos = [
  offthedock,
  wander,
  oceanoutpost,
  grimmfilms,
  fivestar,
  rsk,
  strikeforce,
  tss,
  watsonbeats,
  whitediamond,
  giv,
  zummo
]

export default Logos;
